
import http from './http'

export const api = {
  //扫码登录，获取uuid
  getUUID(params) {
    return http.get('acl/wx/qrcode/getUUID/', params)
  },
  //扫码登录，检查uuid状态
  checkUUID(params) {
    return http.get('acl/wx/qrcode/checkUUID/', params)
  },
  //扫码登录，绑定并登录
  bindAndLogin(params) {
    return http.get('acl/wx/bindCount/', params)
  },
  //扫码登录，绑定并登录
  bindAndLoginToo(params) {
    return http.get('acl/wx/neutral/bindCount/', params)
  },
  //新增发送验证码
  sendSmsCode(params) {
    return http.post('web/sms/smsCode', params)
  },
  //用户名 密码登录
  userLogin(params) {
    return http.post('acl/user/login', params)
  },
  //短信验证码登录
  TextLogin(params) {
    return http.post('acl/sms/login', params)
  },
  //验证手机号是否存在
  isExist(params) {
    return http.get('acl/user/isExist/', params)
  },
  //忘记密码 重置密码
  updatePasswordByPhone(params) {
    return http.post('acl/user/updatePasswordByPhone/', params)
  },
  //注册 完成
  register(params) {
    return http.post('acl/user/register/', params)
  },
  // 验证验证码
  verifyCode(params) {
    return http.post('web/sms/verifyCode', params)
  },
  // 获取用户信息
  getUserInfo(params) {
    return http.get('acl/index/info', params)
  },
  // 获取侧边栏
  getPermissionList(params) {
    return http.get('acl/index/menu', params)
  },
  // 获取消息总数
  getMessageCount(params) {
    return http.get('acl/message/count/', params)
  },
  // 获取菜单管理列表
  getMenuList(params) {
    return http.get('acl/permission/findAll', params)
  },
  // 新增菜单
  menuAdd(params) {
    return http.post('acl/permission/save', params)
  },
  // 编辑菜单管理
  menuUpdate(params) {
    return http.put('acl/permission/update', params)
  },
  // 删除菜单
  menuDel(params) {
    return http.remove('acl/permission/remove/', params)
  },
  // 获取用户管理列表和总数
  getUserList(params) {
    return http.get('acl/user/list/', params)
  },
  //删除用户
  userDel(params) {
    return http.remove('acl/user/remove/', params)
  },
  //批量删除用户
  userDelAll(list) {
    return http.removeAll('/acl/user/batchRemove', list)
  },
  // 新增用户
  userAdd(params) {
    return http.post('acl/user/save/', params)
  },
  // 修改编辑用户
  userUpdate(params) {
    return http.put('acl/user/update/', params)
  },
  //查询所有用户角色
  getRoleList(params) {
    return http.get('acl/role/list', params)
  },
  //获取所有学校列表
  getSchoolList(params) {
    return http.get('web/school/query/', params)
  },
  // 新增学校
  schoolAdd(params) {
    return http.post('web/school/insert/', params)
  },
  //删除学校
  schoolDel(params) {
    return http.remove('web/school/delete/', params)
  },
  //批量删除学校
  schoolDelAll(list) {
    return http.removeAll('web/school/batchDelete/', list)
  },
  // 修改编辑学校
  schoolUpdate(params) {
    return http.post('web/school/update/', params)
  },
  //获取所有班级列表
  getClassList(params) {
    return http.get('web/school/xnclass/query/', params)
  },
  //获取学段
  getRank(params) {
    return http.get('web/school/xnclass/query/rank/', params)
  },
  //获取年级
  getGrade(params) {
    return http.get('web/school/xnclass/query/grade/', params)
  },
  //获取班级
  getClass(params) {
    return http.get('web/school/xnclass/query/classnum/', params)
  },
  // 新增班级
  classAdd(params) {
    return http.post('web/school/xnclass/insert/', params)
  },
  // 班级解绑教室
  classBind(params) {
    return http.get('web/school/xnclass/bindClassroom/', params)
  },
  // 班级解绑教室
  classUnbind(params) {
    return http.get('web/school/xnclass/unBindClassroom/', params)
  },
  //获取未被绑定的教室列表
  getUnbindClassroomList(params) {
    return http.get('web/school/classroom/getAllUnBindClassroom/', params)
  },
  // 修改编辑班级
  classUpdate(params) {
    return http.post('web/school/xnclass/update/', params)
  },
  //删除班级
  classDel(params) {
    return http.remove('web/school/xnclass/delete/', params)
  },
  //批量删除班级
  classDelAll(list) {
    return http.removeAll('web/school/xnclass/batchDelete/', list)
  },
  //获取该学校所有老师
  getAllSchoolTeachers(list) {
    return http.get('acl/user/allSchoolTeachers/', list)
  },
  //老师绑定班级
  addbindTeacher(list) {
    return http.post('web/school/userClass/add/', list)
  },
  //绑定某班级的老师列表
  getBindTeacher(list) {
    return http.get('web/school/userClass/getTeacher/', list)
  },
  //解除老师和班级绑定
  deleteBindTeacher(list) {
    return http.remove('web/school/userClass/delete/', list)
  },
  //获取楼栋列表
  getBuildList(params) {
    return http.get('web/school/building/query/', params)
  },
  // 新增楼栋
  buildAdd(params) {
    return http.post('web/school/building/insert/', params)
  },
  //删除楼栋
  buildDel(params) {
    return http.remove('web/school/building/delete/', params)
  },
  //批量删除楼栋
  buildDelAll(list) {
    return http.removeAll('web/school/building/batchDelete/', list)
  },
  // 修改编辑楼栋
  buildUpdate(params) {
    return http.post('web/school/building/update/', params)
  },
  //获取教室列表
  getClassroomList(params) {
    return http.get('web/school/classroom/queryAll/', params)
  },
  // 新增教室
  classroomAdd(params) {
    return http.post('web/school/classroom/insert/', params)
  },
  // 修改编辑教室
  classroomUpdate(params) {
    return http.post('web/school/classroom/update/', params)
  },
  //删除教室
  classroomDel(params) {
    return http.remove('web/school/classroom/delete/', params)
  },
  //批量删除教室
  classroomDelAll(list) {
    return http.removeAll('web/school/classroom/batchDelete/', list)
  },
  // 获取老师管理列表和总数
  getTeacherList(params) {
    return http.get('acl/user/searchSchoolUser', params)
  },
  // 新增老师
  teacherAdd(params) {
    return http.post('acl/user/addTeacher/', params)
  },
  // 批量新增老师
  teacherAllAdd(params) {
    return http.post('acl/user/addBatch/', params)
  },
  // 下载excel模板
  downloadExecl(params) {
    return http.get('web/oss/getExecelTemplate/', params)
  },
  // 修改编辑老师
  teacherUpdate(params) {
    return http.post('acl/user/updateTeacher/', params)
  },
  // 权限管理  获取角色列表
  getRoleList(params) {
    return http.get('acl/role/list/', params)
  },
  // 权限管理  删除角色
  removeRole(params) {
    return http.remove('acl/role/remove/', params)
  },
  // 权限管理  新增角色
  addRole(params) {
    return http.post('acl/role/save', params)
  },
  // 权限管理 获取角色信息
  getRoleInfo(params) {
    return http.get('acl/role/getRoleInfo/', params)
  },
  // 权限管理 修改角色信息
  upDataRole(params) {
    return http.put('acl/role/update', params)
  },
  // 个人中心 获取Sts临牌
  getStsInfo(params) {
    return http.get('web/oss/getStsInfo/', params)
  },
  //个人中心 获取头像地址
  getAvatarUrl(params) {
    return http.get('web/oss/getAvatarUrl/', params)
  },
  //个人中心 更新头像
  updateAvatar(params) {
    return http.post('acl/user/userUpdateAvatar/', params)
  },
  //个人中心 更新个人信息
  updateInfo(params) {
    return http.post('acl/user/userUpdateInfo/', params)
  },
  //个人中心 修改密码
  updatePassword(params) {
    return http.post('acl/user/userUpdatePassword/', params)
  },
  //个人中心 修改密码
  updatePasswordToo(params) {
    return http.post('acl/user/neutral/userUpdatePassword/', params)
  },
  //个人中心 微信解除绑定
  unbundingWx(params) {
    return http.get('acl/user/unbundingWx/', params)
  },
  //个人中心 绑定微信
  bundingWx(params) {
    return http.get('acl/wx/bindWxScan/', params)
  },
  //个人中心 绑定微信
  bundingWxToo(params) {
    return http.get('acl/wx/neutral/bindWxScan/', params)
  },
  //个人中心 查看微信扫码状态
  isBindSuccess(params) {
    return http.get('acl/wx/isBindSuccess/', params)
  },
  //个人中心 新消息通知列表
  getMessageList(params) {
    return http.get('acl/message/list/', params)
  },
  //个人中心 历史消息列表
  getHistoryMessageList(params) {
    return http.get('acl/message/listAll/', params)
  },
  //个人中心 删除消息
  delMessage(params) {
    return http.remove('acl/message/delete/', params)
  },
  //个人中心 通过审核
  passReview(params) {
    return http.get('acl/user/passReview/', params)
  },
  //个人中心 不通过审核
  unPassReview(params) {
    return http.get('acl/user/unPassReview/', params)
  },
  //终端管理 获取终端列表
  getDeviceList(params) {
    return http.get('web/device/list/', params)
  },
  //终端管理 添加设备
  addDevice(params) {
    return http.post('web/device/addDevice/', params)
  },
  //终端管理 修改设备信息
  updateDevice(params) {
    return http.post('web/device/updateBindRel/', params)
  },
  //终端管理 教室解绑设备
  unBindDevice(params) {
    return http.post('web/device/unBindDevice/', params)
  },
  //终端管理 获取未被绑定设备的教室列表
  getAllUnBindDeviceClassroom(params) {
    return http.get('web/school/classroom/getAllUnBindDeviceClassroom/', params)
  },
  //数据分析 获取设备信息
  getTotalDeviceInfo(params) {
    return http.get('web/device/getTotalDeviceInfo/', params)
  },
  //消息推送 获取消息推送列表
  getPushLogList(params) {
    return http.get("web/device/pushLog/list/", params)
  },
  //文件下发 获取文件下发列表
  getPushFileLog(params) {
    return http.get("web/device/pushFileLog/list/", params)
  },
  //文件下发 获取文件下发的下载地址
  getPushFileUrl(params) {
    return http.get("web/oss/getPushFileUrl/", params)
  },
  //操作日志 获取操作日志列表
  getOperationLogList(params) {
    return http.get("web/device/operationLog/list/", params)
  },
  //数据总概 获取各个时间段设备在线数
  getOnlineInfo(params) {
    return http.get("web/device/onlineInfo/getInfo/", params)
  },
  //数据总概 获取各个省份的终端数
  getDeviceOrder(params) {
    return http.get("web/school/deviceOrder/", params)
  },
  //数据总概 获取学校排名
  getSchoolDeviceOrder(params) {
    return http.get("web/device/schoolDeviceOrder/", params)
  },
  //数据分析 获取各个软件排行
  getListOrder(params) {
    return http.get("web/device/softwareInfo/listOrder/", params)
  },
  //数据分析 获取各个时间段设备在线数
  getSchoolOnlineInfo(params) {
    return http.get("web/device/onlineInfo/getSchoolOnlineInfo/", params)
  },
  //数据分析 获取各个时间段设备在线时长
  getOnlineList(params) {
    return http.get("web/device/onlineOffline/getOnlineList/", params)
  },
  //数据分析 获取设备状态评分
  getScoreList(params) {
    return http.get("web/device/score/getScoreList/", params)
  },
  //报修管理 web获取设备报修列表
  getRepairList(params) {
    return http.get("web/repair/record/listAllRecords/", params)
  },
  //报修管理 删除设备报修列表
  delNotes(params) {
    return http.removeAll("web/repair/record/delete/", params)
  },
  //报修管理 全删设备报修列表
  delNotesAll(params) {
    return http.removeAll("web/repair/record/deleteBatch/", params)
  },
  //报修管理 查看图片和视频
  getMediaUrl(params) {
    return http.post("web/repair/media/getMediaUrl/", params)
  },
  //标签管理 获取身份标签列表
  getContagList(params) {
    return http.get("web/repair/conTag/list/", params)
  },
  //标签管理 新增身份标签
  addContag(params) {
    return http.post("web/repair/conTag/add/", params)
  },
  //标签管理 编辑身份标签
  updateContag(params) {
    return http.put("web/repair/conTag/update/", params)
  },
  //标签管理 删除身份标签
  delContag(params) {
    return http.removeAll("web/repair/conTag/delete/", params)
  },
  //定时任务 增加定时指令
  addScheduleJob(params) {
    return http.post("web/device/scheduleJob/add/", params)
  },
  //定时任务 增加定时推送
  addMessageScheduleJob(params) {
    return http.post("web/device/scheduleJob/addMessage/", params)
  },
  //定时任务 删除定时指令列表
  delScheduleJob(params) {
    return http.remove("web/device/scheduleJob/delete/", params)
  },
  //定时任务 获取定时指令列表
  getScheduleJobList(params) {
    return http.get("web/device/scheduleJob/list/", params)
  },
  //定时任务 查看详情
  getScheduleJobDetail(params) {
    return http.get("web/device/scheduleJob/detail/", params)
  },
  //定时任务 改变一行所有设备的定时状态
  changeScheduleJobStatus(params) {
    return http.put("web/device/scheduleJob/changeStatus/", params)
  },
  //定时任务 改变单台设备的定时状态
  changeJobDevStatus(params) {
    return http.put("web/device/jobDev/status/", params)
  },
  //参数管理 参数列表
  getParamsList(params) {
    return http.get("web/software/parameterType/list/", params)
  },
  //参数管理 新增参数列表
  addParams(params) {
    return http.post("web/software/parameterType/add/", params)
  },
  //参数管理 删除参数列表
  delParams(params) {
    return http.remove("web/software/parameterType/delete/", params)
  },
  //参数管理 编辑参数列表
  updateParams(params) {
    return http.put("web/software/parameterType/update/", params)
  },
  //参数数据 参数数据列表
  getParamValueList(params) {
    return http.get("web/software/paramValue/list/", params)
  },
  //参数数据 新增参数数据列表
  addParamValue(params) {
    return http.post("web/software/paramValue/add/", params)
  },
  //参数数据 删除参数数据列表
  delParamValue(params) {
    return http.remove("web/software/paramValue/delete/", params)
  },
  //参数数据 编辑参数数据列表
  updateParamValue(params) {
    return http.put("web/software/paramValue/update/", params)
  },
  //根据参数值查询参数数据信息
  getValues(params) {
    return http.get("web/software/parameterType/getValues/", params)
  },
  //软件升级 上传版本
  uploadVersion(params) {
    return http.post("web/software/software/add/", params)
  },
  //软件升级 获取列表
  getVersionList(params) {
    return http.get("web/software/software/list/", params)
  },
  //软件升级 下载软件获取软件下载地址
  downloadVersion(params) {
    return http.get("web/software/software/download/", params)
  },
  //软件升级 删除软件版本
  delVersion(params) {
    return http.remove("web/software/software/delete/", params)
  },
  //软件升级 最新软件版本状态改变
  changeVersion(params) {
    return http.put("web/software/software/changeStatus/", params)
  },
  //我的文档 判断是否有网盘
  isOwnDisk(params) {
    return http.get("web/cloudDisk/isOwnDisk/", params)
  },
  //我的文档 添加网盘
  addCloudDisk(params) {
    return http.post("web/cloudDisk/add/", params)
  },
  //我的文档 查看该用户网盘总使用量和总量
  checkUsed(params) {
    return http.get("web/cloudDisk/checkUsed/", params)
  },
  //我的文档 获取网盘文件夹或文件列表
  getFileList(params) {
    return http.get("web/cloudDisk/list/", params)
  },
  //我的文档 重命名或移动文件
  renameOrMoveFile(params) {
    return http.get("web/cloudDisk/renameOrMoveFile/", params)
  },
  //我的文档 新建文件夹
  mkdir(params) {
    return http.get("web/cloudDisk/mkdir/", params)
  },
  //我的文档 删除文件或文件夹
  deleteFile(params) {
    return http.remove("web/cloudDisk/deleteFile/", params)
  },
  //我的文档 批量删除文件
  deleteFileAll(params) {
    return http.removeAll("web/cloudDisk/deleteBatch/", params)
  },
  //我的文档 下载文件
  getDownloadUrl(params) {
    return http.get("web/cloudDisk/getDownloadUrl/", params)
  },
  //我的文档 得到文件夹列表
  getDirStruct(params) {
    return http.get("web/cloudDisk/getDirStruct/", params)
  },
  //我的文档 老师获取设备列表
  getTeacherDevice(params) {
    return http.get("web/device/getTeacherDevice/", params)
  },
  //我的文档 推送文件到设备
  pushFileToDevice(params) {
    return http.post("web/device/PushFileToDevice/", params)
  },
  //分享课件查看
  queryShareNotes(params) {
    return http.get("web/oss/getFileList/", params)
  },
  //学生管理 根据不同的角色返回班级列表
  getQueryClassList(params){
    return http.get("web/school/xnclass/queryByTeacher/", params)
  },
  //学生管理 学生列表
  getStudentList(params){
    return http.get("web/school/student/list/", params)
  },
  //学生管理 新增学生
  studentAdd(params) {
    return http.post("web/school/student/", params)
  },
  //学生管理 编辑学生
  studentUpdate(params) {
    return http.put("web/school/student/", params)
  },
  //学生管理 删除学生
  studentDel(params){
    return http.remove("web/school/student/", params)
  },
  //学生管理 批量删除学生
  studentDelAll(params){
    return http.removeAll("web/school/student/deleteBatch/", params)
  },
  //学生管理 下载模板
  getStudentModel(params){
    return http.get("web/school/student/downloadTemp/", params)
  },
  //学生管理 批量导入学生
  studentAddAll(params) {
    return http.post("web/school/student/uploadAddBatchStudent/", params)
  },
}
