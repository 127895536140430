<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <!-- 侧边栏 -->
    <a-layout-sider
      theme="light"
      v-model="collapsed"
      :trigger="null"
      collapsible
      width="208px"
    >
      <!-- 侧边栏logo部分 -->
      <div class="logo logoBox">
        <img src="@/assets/img/silder-logo.png" alt="" />
        <span v-show="!collapsed">智慧校园云平台</span>
      </div>
      <!-- 侧边栏下面部分 -->
      <a-menu
        theme="light"
        mode="inline"
        :selectedKeys="[this.$route.path]"
        :defaultOpenKeys="[defaultOpenKeys]"
      >
        <a-sub-menu v-for="item in layout" :key="item.path">
          <span slot="title">
            <a-icon :type="item.meta.icon" />
            <span>{{ item.meta.title }}</span>
          </span>
          <a-menu-item v-for="child in item.children" :key="child.path">
            <router-link :to="child.path">{{ child.meta.title }}</router-link>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>

    <!-- 右侧部分 -->
    <a-layout>
      <!-- 头部 -->
      <a-layout-header
        style="
          background: #fff;
          padding: 0;
          box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
          z-index: 99;
        "
      >
        <div class="layout-header">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
          <!-- 头部右侧 -->
          <MainHeader />
        </div>
      </a-layout-header>
      <!-- 正文部分 -->
      <a-layout-content
        :style="{
          minHeight: '480px',
        }"
      >
        <router-view></router-view>
        <!-- <div class="icp d-f a-c j-c">
          ©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号
          江苏小牛电子科技有限公司
        </div> -->
      </a-layout-content>
    </a-layout>
    <!-- 右侧部分 -->
  </a-layout>
</template>

<script>
import MainHeader from "./main-item/header";
export default {
  data() {
    return {
      collapsed: false,
      // defaultOpenKeys: this.$route.matched[0].path,
    };
  },
  components: { MainHeader },
  methods: {
    getLayout() {
      this.$store.dispatch("getPermission");
    },
  },
  created() {
    this.getLayout();
  },
  computed: {
    layout() {
      return this.$store.state.list;
    },
    defaultOpenKeys() {
      return this.$route.matched[0].path;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep .ant-menu-root.ant-menu-inline {
  border-right: none;
}
#components-layout-demo-custom-trigger {
  width: 100vw;
  height: 100vh;
  background: #f0f2f5;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

#components-layout-demo-custom-trigger {
  .ant-layout-sider {
    box-shadow: 1px 0px 4px rgba(0, 21, 41, 0.12);
    z-index: 99;
  }
  .logoBox {
    max-width: 208px;
    min-width: 80px;
    height: 64px;
    background: #009fe8;
    box-shadow: 1px 0px 4px rgba(0, 21, 41, 0.2);
    opacity: 1;
    margin: 0;
    box-shadow: rgba(0, 21, 41, 0.12) 0px 1px 4px;
    position: relative;
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 16px;
      left: 24px;
    }
    span {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 22px;
      color: #ffffff;
      opacity: 1;
      position: relative;
      top: 22px;
      left: 72px;
    }
  }
  .icp {
    font-size: 12px;
    margin-top: 40px;
  }
}
</style>
