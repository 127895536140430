/*
 * @Author: 庄志莹
 * @Date: 2020-12-25 09:02:06
 * @LastEditTime: 2021-06-09 11:51:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\router\constantRouterMap.js
 */

const constantRouterMap = [
  {
    path: '/',
    redirect: '/login',
  },
  //入口模块
  {
    path: '/enter',
    name: 'enter',
    meta: {
      title: '入口',
    },
    component: () => import('@/views/enter/index.vue'),
  },
  //登录模块
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/loginModule/login/login.vue'),
  },
  //注册
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册',
    },
    component: () => import('@/views/loginModule/register/register.vue'),
  },
  //忘记密码
  {
    path: '/forget',
    name: 'forget',
    meta: {
      title: '忘记密码',
    },
    component: () => import('@/views/loginModule/forget/forget.vue'),
  },
  //微信扫码
  {
    path: '/scan',
    name: 'scan',
    meta: {
      title: '扫码登录',
    },
    component: () => import('@/views/loginModule/scan/scan.vue'),
  },
  //h5跳转页面
  {
    path: '/bindWechat',
    name: 'bindWechat',
    meta: {
      title: '绑定账户',
    },
    component: () => import('@/views/loginModule/bindWechat/bindWechat.vue'),
  },
   //h5跳转成功登录页面
   {
    path: '/loginSuccess',
    name: 'loginSuccess',
    meta: {
      title: '登录账户',
    },
    component: () => import('@/views/loginModule/loginSuccess/loginSuccess.vue'),
  },
   //h5跳转绑定成功页面
   {
    path: '/bindSuccess',
    name: 'bindSuccess',
    meta: {
      title: '绑定账户',
    },
    component: () => import('@/views/loginModule/bindSuccess.vue'),
  },
  //h5跳转绑定失败页面
  {
    path: '/bindFailure',
    name: 'bindFailure',
    meta: {
      title: '绑定账户',
    },
    component: () => import('@/views/loginModule/bindFailure.vue'),
  },
  //h5跳转微信绑定其他账号页面
  {
    path: '/bindAnother',
    name: 'bindAnother',
    meta: {
      title: '绑定账户',
    },
    component: () => import('@/views/loginModule/bindAnother.vue'),
  },
  //服务协议
  {
    path: '/service',
    name: 'service',
    meta: {
      title: '服务协议',
    },
    component: () => import('@/views/other/ServiceAgreement.vue'),
  },
  //隐私政策
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      title: '隐私政策',
    },
    component: () => import('@/views/other/PrivacyPolicy.vue'),
  },
  //分享课件查看
  {
    path: '/share',
    name: 'share',
    meta: {
      title: '分享课件查看',
    },
    children: [
      {
        path: '/share/:dir(.*)',
        component: () => import('@/views/share/index.vue'),
        meta: {
          title: '分享课件查看',
        },
      }
    ],
    component: () => import('@/views/share/index.vue'),
  },
  //主体
  {
    path: '/main',
    name: 'main',
    meta: {
      title: '主页',
    },
    component: () => import('@/views/main/main.vue'),
  },
]

export default constantRouterMap
